import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import configDb from "../../config";
import { homeActions, tabActions } from "../../store";
import settingIcon from "../../assets/images/settings-icon.svg";
import qrIcon from "../../assets/images/qr-icon.svg";
import previousIcon from "../../assets/images/previous-icon.svg";
import originIcon from "../../assets/images/origin.svg";
import Icon2 from "../../assets/images/icon-2.svg";
import Icon456 from "../../assets/images/icons-456.svg";
import logo from "../../assets/images/actron-logo.png";
import infoIcon from "../../assets/images/question_mark.png";
import videoIcon from "../../assets/images/video-h.svg";
import infoIcon2 from "../../assets/images/info-i.svg";
import videoFile from "../../assets/video/video-help.mp4";
import videoFileSw from "../../assets/video/Video_Help_Swedish.mp4";

import { toast } from "react-toastify";
import carIcon from "../../assets/images/car-icon.svg";
import Utils from "../../utils";

function Header() {
  const navigate = useNavigate();
  const homeData = useSelector((x) => x.home.value);
  const languageData = useSelector((x) => x.language.value);

  const menu = useSelector((x) => x.menu.value);
  const dispatch = useDispatch();
  const [scanResult, setScanResult] = useState("");
  const [bValue, setBValue] = useState("");
  /*const [isShowSearch, setIsShowSearch] = useState(false);*/
  const [isMobility, setIsMobility] = useState(false);
  const [langList, setLangList] = useState([]);
  let errorObjs = {
    from: "",
    to: "",
  };
  const [errors, setErrors] = useState(errorObjs);
  const infoModalRef = useRef(null);
  const infoCloseModalRef = useRef(null);
  const videoRef = useRef(null);
  const languageModalClose = useRef(null);
  const sideBarPopupOpenSetting = useRef(null);
  const ZoomLevels = Utils.detectDevice();
  let selectedLanguages = localStorage.getItem("selectedLanguage");

  const handleModalClose = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [selectedLanguages]);

  useEffect(() => {
    let settingOpened = localStorage.getItem("settingOpened");
    if (!settingOpened) {
      sideBarPopupOpenSetting?.current?.click();
      setTimeout(function () {
        localStorage.setItem("settingOpened", 1);
      }, 2100);
    } else {
      ///sideBarPopupOpenSetting?.current?.click();
    }
  }, [menu, dispatch]);

  useEffect(() => {
    const addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    // debugger
    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: "en",
          includedLanguages:
            "en,fr,de,es,it,hi,sv,ar,el,ru,zh-CN,sk,cs,da,pt,ja,sl,nl,si,no,pl,ur,ml,hi",
        },
        "google_translate_element"
      );
    };
  }, []);

  useEffect(() => {
    setLangList(languageData);
  }, [languageData]);

  useEffect(() => {
    let selectedLanguage = localStorage.getItem("selectedLanguage");
    if (selectedLanguage) {
      let list = [...languageData];
      let newList = [];
      for (let i = 0; i < list.length; i++) {
        let active = false;
        if (list[i]["lang"] === selectedLanguage) {
          active = true;
        }
        newList.push({
          name: list[i]["name"],
          lang: list[i]["lang"],
          icon: list[i]["icon"],
          code: list[i]["code"],
          active: active,
        });
      }
      setLangList(newList);
    }
  }, [languageData]);

  useEffect(() => {
    if (homeData?.a) {
      setScanResult(homeData.a);
      if (homeData.scanner) {
        if (homeData?.a !== homeData?.b) {
          let element = document.getElementById("frmSearch");
          if (element) {
            element.click();
          }
        }
      }
    }
    if (homeData?.a !== homeData?.b) {
      setBValue(homeData?.b ? homeData?.b : "");
    }

    let is_mobility = localStorage.getItem(configDb.data.is_mobility);
    if (is_mobility === "true") {
      setIsMobility(true);
    }
  }, [homeData?.a, homeData?.b, homeData?.scanner]);

  const handleLocationSearch = (e) => {
    e.preventDefault();
    let dataList = { ...homeData };
    let error = false;
    let errorObj = { ...errorObjs };
    if (!dataList.a.trim()) {
      errorObj.from = "This field is required";
      error = true;
    }
    if (!dataList.b.trim() && !dataList.scanner) {
      errorObj.to = "This field is required";
      error = true;
    } else if (dataList.a.trim() === dataList.b.trim() && !dataList.scanner) {
      errorObj.to =
        "Both input are not same. Please enter different end point.";
      error = true;
    }
    setErrors(errorObj);
    if (error) return;
    dispatch(tabActions.home({ tab: 4 }));
  };

  const handelPrevious = () => {
    let storageData = localStorage.getItem(configDb.data.store_storage);
    if (storageData) {
      let data = JSON.parse(storageData);
      if (data.length > 0) {
        let index = -1;
        if (bValue) {
          index = data.findIndex((x) => x.to === bValue);
          if (index > -1) {
            index = index - 1;
          }
        }
        let obj = {};
        if (index === -1) {
          obj = data.pop();
        } else if (index > -1) {
          obj = data[index];
        }
        if (obj.to) {
          //setScanResult(obj.from);
          setBValue(obj.to);
          let data = { ...homeData };
          //data.a = obj.from;
          data.b = obj.to;
          dispatch(homeActions.home(data));
        }
      }
    }
  };
  const handelPreviousHorizontal = () => {
    let storageData = localStorage.getItem(configDb.data.store_storage);
    if (storageData) {
      let data = JSON.parse(storageData);
      if (data.length > 0) {
        let index = -1;
        if (bValue) {
          index = data.findIndex((x) => x.to === bValue);
          if (index > -1) {
            index = index - 1;
          }
        }
        let obj = {};
        if (index === -1) {
          obj = data.pop();
        } else if (index > -1) {
          obj = data[index];
        }
        if (obj.to) {
          setBValue(obj.to);
          let data = { ...homeData };
          data.a = obj.from;
          data.b = obj.to;
          dispatch(homeActions.home(data));
          let element = document.getElementById("frmSearch");
          element.click();
        }
      }
    }
  };

  // let isOriginHandled = false;

  const handelOrigin = () => {
    let data = { ...homeData };
    if (data.origin) {
      data.b = data.origin;
      data.scanner = true;
      dispatch(homeActions.home(data));

      let zoom = document.querySelector("svg").style.zoom;
      if (zoom && !ZoomLevels) {
        setTimeout(() => {
          document
            .querySelector(".showMapContainer")
            .classList.remove("zoom-svg-in");
          document.querySelector("svg").style.zoom = zoom;
        }, 3000);
      }
    }
  };

  const handelLocationA = (e) => {
    let data = { ...homeData };
    let value = e.target.value;
    if (e.target.value === data.b && data.b !== "") {
      toast.error("A or B can not same.Please select another");
    } else {
      if (value) {
        data.a = value;
        dispatch(homeActions.home(data));
      }
      setScanResult(e.target.value);
      /*setIsShowSearch(true);*/
      let myElement = document.getElementById("tab_1_link");
      if (myElement.classList.contains("current")) {
        if (value) {
          let locationElement =
            document.getElementsByClassName("tab-content-table");
          for (let i = 0; i < locationElement.length; i++) {
            let locationName = locationElement[i].textContent.toLowerCase();
            if (locationName.includes(value.toLowerCase())) {
              locationElement[i].classList.remove("d-none");
            } else {
              locationElement[i].classList.add("d-none");
            }
          }
        } else {
          handleLocationABlue();
        }
      }
    }
  };

  const handleLocationABlue = () => {
    let locationElement = document.getElementsByClassName("tab-content-table");
    for (let i = 0; i < locationElement.length; i++) {
      locationElement[i].classList.remove("d-none");
    }
  };
  const handelLocationB = (e) => {
    let locationElement = document.getElementsByClassName("tab-content-table");
    for (let i = 0; i < locationElement.length; i++) {
      locationElement[i].classList.remove("d-none");
    }
    let data = { ...homeData };
    if (e.target.value === data.a && data.a !== "") {
      toast.error("A or B can not same.Please select another");
    } else {
      data.b = e.target.value;
      dispatch(homeActions.home(data));
      setBValue(e.target.value);
      let myElement = document.getElementById("tab_1_link");
      if (myElement.classList.contains("current")) {
        if (e.target.value) {
          let locationElement =
            document.getElementsByClassName("tab-content-table");
          for (let i = 0; i < locationElement.length; i++) {
            let locationName = locationElement[i].textContent.toLowerCase();
            if (locationName.includes(e.target.value.toLowerCase())) {
              locationElement[i].classList.remove("d-none");
            } else {
              locationElement[i].classList.add("d-none");
            }
          }
        } else {
          handleLocationABlue();
        }
      }
    }
  };
  const handleMobility = (e) => {
    setIsMobility(!isMobility);
    if (e.target.checked) {
      localStorage.setItem(configDb.data.is_mobility, true);
    } else {
      localStorage.removeItem(configDb.data.is_mobility);
    }
  };
  const handleLanguage = (e, data) => {
    e.preventDefault();

    const langElement = document.getElementsByClassName("goog-te-combo")[0];

    if (langElement) {
      langElement.value = data.lang;

      const changeEvent = new Event("change");
      langElement.dispatchEvent(changeEvent);

      localStorage.setItem("selectedLanguage", data.lang);

      setLangList((prevList) =>
        prevList.map((item) => ({
          ...item,
          active: item.lang === data.lang,
        }))
      );

      if (languageModalClose?.current) {
        languageModalClose.current.click();
      }
    }
  };

  const addActiveClass = (e, id, tab) => {
    e.preventDefault();
    let data = { ...homeData };
    let allSearch = document.getElementsByClassName("searchBtn");
    for (let i = 0; i < allSearch.length; i++) {
      allSearch[i].classList.remove("btn-dark-search");
    }
    document.getElementById(id).classList.add("btn-dark-search");
    sessionStorage.setItem("input", tab);
    if (tab === "A") {
      data.info_raw = "Select Start (A)";
    } else {
      data.info_raw = "Select Destination (B)";
    }
    dispatch(homeActions.home(data));
  };

  const [dataQrType, setDataQrType] = useState(() => {
    const value = localStorage.getItem("originType3");
    return value ? parseInt(value, 10) : null;
  });

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedValue = localStorage.getItem("originType3");
      if (updatedValue && parseInt(updatedValue, 10) !== dataQrType) {
        setDataQrType(parseInt(updatedValue, 10));
        window.location.reload();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [dataQrType]);

  const handleModalOpen = (e) => {
    e.preventDefault();
    infoModalRef?.current.click();
  };

  return (
    <>
      <section className="top-header">
        {homeData.search_area && (
          <div className="container">
            <div className="row">
              <div className="inner-content col-12">
                <div className="row">
                  <div className="col-2">
                    <div
                      className="icon-settings-left"
                      role={`button`}
                      data-bs-toggle="offcanvas"
                      data-bs-target="#sidebar-offcanvas"
                    >
                      <img src={settingIcon} alt="settings" />
                      <p>Settings</p>
                    </div>
                  </div>
                  <div className="col-8 p-0">
                    <div className="form-floating">
                      <div className="form-selbox">
                        <div className="input-group">
                          <input
                            type="search"
                            className="form-control"
                            value={scanResult}
                            onChange={handelLocationA}
                            onFocus={(e) => {
                              sessionStorage.setItem("input", "A");
                              document.getElementById(
                                "location-head"
                              ).innerHTML = "Select Start (A)";
                            }}
                            placeholder="Select Start (A)"
                          />
                          {errors.from && (
                            <div className={`input-error`}>{errors.from}</div>
                          )}
                          <div className="submenu-box">
                            <p className={`notranslate`}>A</p>
                          </div>
                          <button
                            className="btn btn-search searchBtn"
                            onClick={(e) => addActiveClass(e, "btnA", "A")}
                            type="button"
                            id={`btnA`}
                          >
                            <i className="fa fa-search" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="icon-settings-right">
                      <NavLink
                        to={"/scanner"}
                        style={{ textDecoration: "none" }}
                      >
                        <img src={qrIcon} alt="qr-code" />
                        <p>Scan</p>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <div className="inner-content mb-0 col-12">
                <div className="row">
                  <div className="col-2">
                    <div
                      className="icon-settings-left"
                      onClick={handelPrevious}
                    >
                      <img src={previousIcon} alt="Previous" />
                      <p className="mt-3">Previous</p>
                    </div>
                  </div>
                  <div className="col-8 p-0">
                    <div className="form-floating">
                      <div
                        className="form-selbox"
                        id={`formSubmitSearch`}
                        onSubmit={handleLocationSearch}
                      >
                        <div className="input-group">
                          <input
                            className="form-control"
                            type="search"
                            value={bValue}
                            placeholder={`Chose Destination`}
                            onChange={handelLocationB}
                            onFocus={(e) => {
                              sessionStorage.setItem("input", "B");
                              document.getElementById(
                                "location-head"
                              ).innerHTML = "Select Destination (B)";
                            }}
                          />
                          {errors.to && (
                            <div className={`input-error`}>{errors.to}</div>
                          )}
                          <div className="box-b">
                            <p className={`notranslate`}>B</p>
                          </div>
                          <button
                            className="btn btn-search btn-dark-search searchBtn"
                            onClick={(e) => addActiveClass(e, "frmSearch", "B")}
                            type="button"
                            id="frmSearch"
                          >
                            <i className="fa fa-search" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="icon-settings-right" onClick={handelOrigin}>
                      {parseInt(homeData.qr_type) === 3 ? (
                        <img src={carIcon} alt="Origin" />
                      ) : (
                        <img src={originIcon} alt="Origin" />
                      )}
                      <p>Origin</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="description">
                  <p>“Search Alternatives”</p>
                </div>
              </div>
            </div>
          </div>
        )}
        {!homeData.search_area && (
          <div className="container">
            <div className="settings-row-three row settings-row-next">
              <div
                className={`col-custom-4`}
                data-bs-toggle="offcanvas"
                ref={sideBarPopupOpenSetting}
                id={`sidebar-menu-icon`}
                data-bs-target="#sidebar-offcanvas"
              >
                <img
                  src={settingIcon}
                  alt="settings"
                  className={`setting-header`}
                  width={25}
                />
              </div>
              <div className={`col-custom-5`}>
                <img
                  src={qrIcon}
                  alt="qr-code"
                  onClick={(e) => {
                    navigate("/scanner");
                  }}
                />
              </div>

              <div className={`col-custom-6`}>
                {dataQrType === 3 ? (
                  <>
                    <img
                      src={carIcon}
                      alt="Car Icon"
                      onClick={() => {
                        handelOrigin();
                      }}
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={originIcon}
                      alt="Origin Icon"
                      onClick={() => {
                        handelOrigin();
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="container d-none">
          <div className="settings-row">
            <div className="icon-bar">
              <div className="info-link">
                <img src={infoIcon} alt="" />
              </div>
              <div
                className="setting-block"
                data-bs-toggle="offcanvas"
                data-bs-target="#sidebar-offcanvas"
              >
                <img src={settingIcon} alt="settings" />
              </div>
              <div className="previous-icon" onClick={handelPreviousHorizontal}>
                <img src={previousIcon} alt="Previous" />
              </div>
            </div>
            <div className="description-search">
              <p>“Search Alternatives”</p>
            </div>
            <div className="scan-origin-block">
              <div
                className="qr-icon"
                onClick={(e) => {
                  navigate("/scanner");
                }}
              >
                <img src={qrIcon} alt="qr-code" />
              </div>
              <div className="car-icon" onClick={handelOrigin}>
                <img src={originIcon} alt="Origin" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="offcanvas offcanvas-start sidebar-left"
        tabIndex="-1"
        id="sidebar-offcanvas"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <div className="logo-images-offcanvas text-center">
            {homeData?.clientData?.logotype_image ? (
              <img
                src={homeData?.clientData?.logotype_image}
                alt="..."
                className="w-50"
              />
            ) : (
              <img src={logo} alt="..." />
            )}
          </div>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            ref={languageModalClose}
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body">
          <h5 className="head-title">Select Your Language</h5>
          <div className="language-block d-none">
            <div id={`google_translate_element`} />
          </div>
          <div className="language-block">
            <ul className="language-nav notranslate">
              {langList &&
                langList.map((lang, index) => (
                  <li
                    key={index}
                    role={`button`}
                    onClick={(e) => handleLanguage(e, lang)}
                    className={`changeLanguage cursor-pointer ${
                      lang.active ? `active` : ``
                    }`}
                  >
                    <img className="icon" src={lang.icon} alt="..." />
                    {lang.name}
                  </li>
                ))}
            </ul>
          </div>
          <div className="mobility-section">
            <ul className="mobility-nav">
              <li>
                <img src={Icon2} alt="icon" />
              </li>
              <li>
                <img src={Icon456} alt="icons-456" />
              </li>
              <li>Limited Mobility</li>
            </ul>
            <div className="switc-block">
              <div className="form-check form-switch form-switch-md">
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={handleMobility}
                  checked={isMobility}
                  id="flexSwitchCheckDefault"
                />
              </div>
            </div>
          </div>
          <div className="navbar-bottom">
            <li>
              <a
                href={"!#"}
                onClick={handleModalOpen}
                style={{ textDecoration: "none" }}
              >
                <img src={videoIcon} alt="videoIcon" />
                Video Help
              </a>
              <button
                className={`d-none`}
                data-bs-toggle="modal"
                data-bs-target="#subcategories-shopping"
                ref={infoModalRef}
              />
            </li>
            <li>
              <NavLink to={"/about"} style={{ textDecoration: "none" }}>
                <img src={infoIcon2} alt="infoIcon" />
                About
              </NavLink>
            </li>
          </div>
          <div className="mt-4 text-center">
            <div className="logo-images-offcanvas">
              <img src={logo} alt="..." />
            </div>
          </div>

          <div className="mt-4 text-center">
            {homeData.getAboutInfo?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div>
                    <h3 style={{ fontSize: "17px", color: "#ceb96f" }}>
                      {item.about_text}
                    </h3>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
      <div
        id="subcategories-shopping"
        className="modal fade subcategories-modal video"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div
          className={`modal-dialog modal-dialog-centered`}
          style={{ maxWidth: "326px" }}
        >
          <div className="modal-content">
            <button
              className="btn-close"
              data-bs-dismiss="modal"
              ref={infoCloseModalRef}
              onClick={handleModalClose}
            ></button>

            <video controls ref={videoRef}>
              <source
                src={selectedLanguages === "sv" ? videoFileSw : videoFile}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          <button
            className={`d-none`}
            data-bs-dismiss="modal"
            ref={infoCloseModalRef}
          />
        </div>
      </div>
    </>
  );
}

export default Header;
