import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const name = "language";
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

export const languageActions = { ...slice.actions, ...extraActions };
export const languageReducer = slice.reducer;

function createInitialState() {
  return {
    value: [
      {
        name: "English",
        lang: "en",
        icon: "images/language/flag-uk.png",
        active: true,
      },
      {
        name: "French",
        lang: "fr",
        icon: "https://www.worldometers.info//img/flags/small/tn_fr-flag.gif",
        active: true,
      },
      {
        name: "German",
        lang: "de",
        icon: "images/language/german-flag.png",
        active: true,
      },
      {
        name: "Spanish",
        lang: "es",
        icon: "images/language/spanish.png",
        active: true,
      },
      {
        name: "Italian",
        lang: "it",
        icon: "https://www.worldometers.info//img/flags/small/tn_it-flag.gif",
        active: true,
      },
      {
        name: "Hindi",
        lang: "hi",
        icon: "https://www.worldometers.info//img/flags/small/tn_in-flag.gif",
        active: true,
      },
      {
        name: "Swedish",
        lang: "sv",
        icon: "https://www.worldometers.info//img/flags/small/tn_sw-flag.gif",
        active: true,
      },
      {
        name: "Arabic",
        lang: "ar",
        icon: "https://www.worldometers.info//img/flags/small/tn_ae-flag.gif",
        active: true,
      },
      {
        name: "Greek",
        lang: "el",
        icon: "https://www.worldometers.info//img/flags/small/tn_gr-flag.gif",
        active: true,
      },
      {
        name: "Russian",
        lang: "ru",
        icon: "https://www.worldometers.info//img/flags/small/tn_rs-flag.gif",
        active: true,
      },
      {
        name: "Chinese",
        lang: "zh-CN",
        icon: "https://www.worldometers.info//img/flags/small/tn_ch-flag.gif",
        active: true,
      },
      {
        name: "Slovak",
        lang: "sk",
        icon: "https://www.worldometers.info//img/flags/small/tn_lo-flag.gif",
        active: true,
      },
      {
        name: "Czech",
        lang: "cs",
        icon: "https://www.worldometers.info//img/flags/small/tn_ez-flag.gif",
        active: true,
      },
      {
        name: "Danish",
        lang: "da",
        icon: "https://www.worldometers.info//img/flags/small/tn_da-flag.gif",
        active: true,
      },
      {
        name: "Portugues",
        lang: "pt",
        icon: "https://www.worldometers.info//img/flags/small/tn_po-flag.gif",
        active: true,
      },
      {
        name: "Japanese",
        lang: "ja",
        icon: "https://www.worldometers.info//img/flags/small/tn_ja-flag.gif",
        active: true,
      },
      {
        name: "Slovenian",
        lang: "sl",
        icon: "https://www.worldometers.info//img/flags/small/tn_si-flag.gif",
        active: true,
      },
    ],
  };
}

function createReducers() {
  return {
    setLang,
  };

  function setLang(state, action) {
    state.value = action.payload;
  }
}

function createExtraActions() {
  return {
    lang: lang(),
  };

  function lang() {
    return createAsyncThunk(`${name}/load`, function (arg, { dispatch }) {
      dispatch(languageActions.setLang(arg));
    });
  }
}
